/* eslint-disable */
import React from 'react'
import SeniorWoman from '../images/people/senior-lady-hands-in-pockets.png'
import ManPointing from '../images/people/man-pointing.png'
import CallCenterAgent from '../images/people/call-center-agent.png'
import purpleImgBackground from '../images/backgrounds/purple-img-background.jpg'
import { ShoppingCart } from 'emotion-icons/material'
import { StaticImage } from 'gatsby-plugin-image'
import aetna from '../images/brand-logos/aetna.svg'
import cigna from '../images/brand-logos/cigna.svg'
import regence from '../images/brand-logos/regence.svg'
import united from '../images/brand-logos/united-healthcare.svg'
import wellcare from '../images/brand-logos/wellcare.svg'
import mutual from '../images/brand-logos/mutual-omaha.svg'
import ResponsiveGrid from '../components/GridLayouts/ResponsiveGrid'
import header from '../components/Header'
import footer from '../components/Footer'

import {
  Banner,
  Billboard,
  Columns,
  Column,
  LinkButton,
  List,
  ListItem,
  SplitContent,
  Stack,
  Typography,
  VariableContent,
} from '@leshen/gatsby-theme-leshen'
import { Layout } from '@leshen/gatsby-theme-contentful'

const Home = () => {
  const brandLogos = [
    { src: aetna, alt: '' },
    { src: cigna, alt: '' },
    { src: regence, alt: '' },
    { src: united, alt: '' },
    { src: wellcare, alt: '' },
    { src: mutual, alt: '' },
  ]

  const layoutProps = {
    data: {
      contentfulPage: {
        header: header(),
        footer: footer(),
        seo: {
          title: 'Clearlink Insurance Agency | Insurance Quotes | 833-488-0038',
          description:
            'Call Clearlink Insurance Agency - Your one-stop-shop for Supplemental Health and Medicare Insurance! Our Agents are ready to provide you quotes from multiple partners to get you the rate you deserve.',
          canonical: 'https://clearlinkinsurance.com/',
          robots: 'follow,index',
        },
        path: '/',
        promoCode: '147172',
        ringPool: 'CLI',
        hideStickyCTA: false,
        spanish: false,
        sections: [
          {
            // Add any relevant section data
          },
        ],
      },
      site: {
        siteMetadata: {
          siteURL: 'https://clearlinkinsurance.com/',
          siteName: 'clearlinkinsurance',
          alternateName: 'clearlinkinsurance',
          removeTrailingSlashes: true,
        },
      },
    },
    titleTemplate:
      'Clearlink Insurance Agency | Insurance Quotes | 833-488-0038',
    defaultRobots: 'follow,index',
    main: (
      <>
        <div className="hero-split-content">
          <div className="wrapper">
            <Billboard
              backgroundColor="primary"
              alignImageToBottom
              variant="split"
              image={
                <img
                  src={SeniorWoman}
                  alt="woman with hands in pockets smiling"
                />
              }
              mobileImage={
                <img
                  src={SeniorWoman}
                  alt="woman with hands in pockets smiling"
                />
              }
              mainContent={
                <>
                  <Typography variant="h1">
                    Find Healthcare Plans for Every Lifestyle{' '}
                  </Typography>

                  <LinkButton
                    variant="hero"
                    to="https://my.clearlinkinsurance.com/login"
                    color="secondary"
                  >
                    User Portal Login
                  </LinkButton>
                  <LinkButton
                    variant="hero"
                    to="/confirmation"
                    color="secondary"
                  >
                    Find Your Policy Status
                  </LinkButton>

                  <StaticImage
                    src="../images/illustrations/star-ratings.svg"
                    alt="star ratings"
                    layout="fixed"
                    width={200}
                    height={30}
                    className="star-ratings"
                  />

                  <Typography variant="h4">
                    Based on Trustpilot reviews.
                  </Typography>

                  <div className="ssl-licence-link">
                    <StaticImage
                      src="../images/illustrations/ssl-license.svg"
                      alt=""
                      placeholder="blurred"
                      layout="fixed"
                      width={55}
                      height={30}
                    />
                  </div>
                </>
              }
            />
          </div>
        </div>

        <VariableContent
          mainContent={
            <>
              <Typography variant="h4">
                We work with some of the most trusted insurance brands in the
                country:
              </Typography>
            </>
          }
          alignMainContent="Center"
          alignImageToBottom={false}
          centeredContent={false}
          backgroundColor={null}
          customLink={null}
          className="top-bottom-padding-48 brand-logos"
        >
          <ResponsiveGrid
            images={brandLogos}
            numberOfColumns={6}
            numberOfMobileColumns={2}
            constraint="large"
            columnGap="10px"
          />
        </VariableContent>

        <Banner
          backgroundColor="pink"
          className="cta-banner"
          mainContent={
            <>
              <Typography variant="h4" style={{ color: 'white' }}>
                Qualify for a new Medicare plan?
              </Typography>
              <Typography variant="h4" style={{ color: 'white' }}>
                View plans in your area online now!
              </Typography>
              <LinkButton
                variant="feature"
                to="https://www.sunfirematrix.com/app/consumer/clearlink/#/"
                color="light"
                leftIcon={<ShoppingCart />}
              >
                Browse Plans
              </LinkButton>
            </>
          }
        />

        <SplitContent
          backgroundColor="white"
          alignImageToBottom={false}
          image={<img src={CallCenterAgent} alt="call center agent" />}
          mobileImage={false}
          mainContent={
            <>
              <Typography variant="h2">
                We have more than 10 years of experience as an independent
                insurance agency.
              </Typography>
              <Typography variant="body" className="large">
                Since 2017, we’ve helped more than 100,000 people find the right
                healthcare insurance in all 50 states. With more than 300
                U.S.-based licensed agents, Clearlink Insurance is positioned to
                help you find coverage for you and your family.
              </Typography>
            </>
          }
        />

        <div className="purple-img-background">
          <VariableContent
            mainContent={
              <>
                <Typography variant="h2">
                  Clearlink Insurance Products
                </Typography>
              </>
            }
            alignMainContent="Center"
            style={{
              backgroundImage: `url(${purpleImgBackground})`,
              color: '#fff',
            }}
          >
            <Stack>
              <Columns cardStyled className="text-center">
                <Column backgroundColor="primary">
                  <StaticImage
                    src="../images/illustrations/medical-card.svg"
                    alt="star ratings"
                    layout="fixed"
                    width={100}
                    height={100}
                    className="product-icons"
                  />
                  <Typography variant="body" className="large">
                    Medicare Health Insurance
                  </Typography>
                  <Typography variant="body">
                    Medicare is a federal government health insurance plan
                    primarily for those 65 and older. Our Medicare experts can
                    help you navigate Medicare to determine which plan best
                    suits your health needs. We work with the top insurance
                    companies in the nation and can answer questions about
                    eligibility, enrollment periods, Advantage, and Medigap.
                  </Typography>
                </Column>
                <Column backgroundColor="primary">
                  <StaticImage
                    src="../images/illustrations/heart.svg"
                    alt="star ratings"
                    layout="fixed"
                    width={100}
                    height={100}
                    className="product-icons"
                  />
                  <Typography variant="body" className="large">
                    Supplemental Health Insurance
                  </Typography>
                  <Typography variant="body">
                    You’ve got primary medical insurance, but what about all the
                    out-of-pocket costs your plan might not cover? One of our
                    licensed agents can help you fill the gaps in your coverage
                    with a supplemental insurance plan. Ensuring your health
                    needs are covered is our top priority, and a supplemental
                    insurance plan can help you get there.
                  </Typography>
                </Column>
              </Columns>
            </Stack>
          </VariableContent>
        </div>

        <Billboard
          backgroundColor="white"
          alignImageToBottom
          variant="split"
          image={<img src={ManPointing} alt="man pointing and smiling" />}
          mobileImage={<img src={ManPointing} alt="man pointing and smiling" />}
          mainContent={
            <>
              <Typography variant="h1" className="text-center">
                Why Choose Clearlink Insurance Agency?
              </Typography>
              <List className="text-center-on-small">
                <ListItem>
                  Mix and match your healthcare insurances for maximum coverage.
                </ListItem>
                <ListItem>
                  Compare plans across multiple providers that fit your health
                  needs and budget.
                </ListItem>
                <ListItem>
                  Get enrollment help and customer service all in one place.
                </ListItem>
                <ListItem>
                  Work with insurance experts to ensure you’re getting the right
                  plan for you.
                </ListItem>
                <ListItem>
                  Talk to a U.S.-based licensed agent who’s ready to answer your
                  questions.
                </ListItem>
                <ListItem>
                  Get free, personalized quotes for upfront insurance
                  transparency.
                </ListItem>
              </List>
            </>
          }
        />
      </>
    ),
  }

  return <Layout {...layoutProps} />
}
export default Home
